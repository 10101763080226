<template>
    <div>
        <div class="panel">
            <div v-for="item in panel_list" class="item">
              <div class="title">{{item.title}}</div>
              <div class="moneyHolder">{{item.money}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import {getResNew} from "../util/axiosAPI";

export default {
    name:'infoPanel',
  mounted() {
      const _this = this;
    getResNew(
        '/bill/allDetailBillAmount').then(
        res =>{

          for (let i = 0; i < res.length; i++) {

            _this.panel_list[i].money = res[i].toFixed(2);

          }
        }
    )
  },


  data(){
        return{
          panel_list:[
            {
              title:'今天',
              money: 0,
            },
            {
              title:'昨天',
              money: 0
            },
            {
              title:'本周',
              money: 0
            },
            {
              title:'上周',
              money: 0
            },
            {

              title:'本月',
              money: 0
            },
            {
              title:'上月',
              money: 0
            },
          ]
        }
    },
}
</script>

<style scoped>
.panel{
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  border: 2px solid white;
  border-radius: 10px;
  margin: 5px 8px 5px 8px;
  padding:8px;
  font-family: "Academy Engraved LET",monospace;
}
.container div{
    text-align: center;
}
.title{
  margin: 2px 2px 2px 2px;
  font-size: 18px;
}
.moneyHolder{
  font-size: 25px;
}
</style>