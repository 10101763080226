import axios from "axios";

export function postResNew(url, data) {
    let headers =  {
                'usertoken': localStorage.getItem('Authorization')
            }
    return axios.post(
        url,
        data, {
                headers:headers
        }
    ).then(
        res => res.data
    ).catch(err => {
        console.log(err);
    })

}


export function getResNew(url) {
     let headers =  {
                'usertoken': localStorage.getItem('Authorization')
            }
    return axios.get(url, {
        headers: headers
    }).then(
        res => res.data
    ).catch(
        err => console.log(err)
    )
}
