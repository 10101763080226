<template>

    <div class="itemContainer">

    
    <div class="delete" @click="deleteBill"><span id="close"></span></div>
    <div class="item">
    <div>
    <div class="titleAndTag">
      <div class="title">{{bill.title}}</div>
      <div v-for="tag in tags" class="tag">{{tag.name}}</div></div>
    <div class="date"  :style="{color: colorOfDate(bill.date)} " ><span>{{bill.date}}</span></div>
    </div>
    <div class="amount" :style="{color: colorOfDate(bill.date)}">-{{bill.amount}}</div>
    </div>

  </div>
</template>

<script setup>
import { computed } from "vue";
const emit = defineEmits(['deleteBill'])

const props = defineProps(['item','index'])

const bill = computed( () => {
    if (Object.keys(props.item).length == 2) {
      return props.item.bill;
    }else{
      return props.item;
    }
})
const tags = computed( () =>{
  
  if (Object.keys(props.item).length == 2) {
      return props.item.tags;
    }else{
      return [];
    }
})
function deleteBill(){
    emit('deleteBill', props.item, props.index);
}

function colorOfDate(datetimeStr){

    const timestamp = Date.parse(datetimeStr);

    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const todayStartTimeStamp = now.getTime();
    const yesterdayStartTimeStamp = todayStartTimeStamp - (24 * 60 * 60 * 1000);

    if (timestamp >= todayStartTimeStamp) {
      return '#40e040'
    } else if (timestamp >= yesterdayStartTimeStamp && timestamp < todayStartTimeStamp) {
      return 'rgb(36,94,255)'
    } else {
      return '#909399'
    }


}
</script>

<style scoped>

.item{
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 5px;

  border-radius: 30px;
  background-color: #FFF;

  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  display: grid;
  grid-template-columns: 73% 27%;
  padding: 13px;/*字和边框的距离*/
  z-index: 1;
}

.title{
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
}

.tag{
  font-size: 10px;
  padding: 3px 5px 3px 5px;
  border:1px solid #20B2AA;
  color: #20B2AA;
  border-radius: 15px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  top: -10px;
  left: 5px;
}
.amount{
  /* color: #40E0D0;*/
  font-size: 23px;
  padding: 7px;
  text-align: right;
  font-weight: bolder;
}

.date{
  margin-top: 7px;
}
.delete{
  width :20px;
  height:20px;
  text-align: center;
  z-index: 3;
  position: absolute;
  top: -6px;
  right: 0px;
  border: 2px solid whitesmoke;
  background-color: whitesmoke;
  border-radius: 50%;
}

.itemContainer{
  position: relative;
}

 #close {
        display: inline-block;
        width: 10px;
        height: 1px;
        background: #333;
        transform: rotate(45deg);
        margin-bottom: 5px;
    }

    #close::after {
        content: '';
        display: block;
        width: 10px;
        height: 1px;
        background: #333;
        transform: rotate(-90deg);
    }
</style>